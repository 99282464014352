import React from 'react';
import { ImageModule } from '../modules/imageModule';

import { FeaturedBlogCard } from './featuredBlogCard';

export const BlogBanner = ({ title, description, featuredPost }) => {
  return (
    <div className="grid grid-cols-14">
      {/* Spacer */}
      <div className="col-start-1 col-end-15 row-start-1 row-end-4 bg-mint-100 relative z-0 flex" />
      {/* Text */}
      <div className="pt-32 pb-24 text-white text-center col-start-2 col-end-14 row-start-2 relative z-10 max-w-2xl mx-auto lg:pt-64">
        <h1 className="isH2 isH1Md">{title}</h1>
        <div className="prose mt-2 max-w-none">
          <p>{description}</p>
        </div>
      </div>
      {/* Image */}
      <div className="col-start-2 col-end-14 row-start-3 row-end-6 xl:row-end-6">
        <FeaturedBlogCard {...featuredPost} />
      </div>
    </div>
  );
};
