import React, { useState } from 'react';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ChevronR from '../../svg/chevron-r.svg';

import cx from 'classnames';

import { useBlogCategorys } from '../../GraphQl/useBlogCategorys';

export const CategoryMenu = ({ pageTitle = 'All', setActiveCat, activeCat }) => {
  const categories = useBlogCategorys();
  const breakpoints = useBreakpoint();
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return breakpoints.sm ? (
    <motion.div className="my-16 px-gutter" layout>
      <div className="border border-peach-100 rounded-xl px-4 py-4">
        <h4 className="text-xl text-peach-100 flex justify-between items-center" onClick={toggle}>
          <div className="text-center flex-grow">{pageTitle}</div>
          <motion.div>
            <ChevronR className="w-2 text-peach-100 fill-current" />
          </motion.div>
        </h4>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="overflow-hidden"
            >
              <div className="grid gap-y-4 py-4">
                <button
                  onClick={() => setActiveCat(null)}
                  className={cx({ 'text-peach-100 underline': !activeCat })}
                >
                  All
                </button>
                {categories.map(({ title, _id }) => (
                  <button
                    onClick={() => setActiveCat(_id)}
                    className={cx({
                      'text-peach-100 underline': activeCat === _id,
                    })}
                  >
                    {title}
                  </button>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  ) : (
    <div className="flex gap-x-3 justify-center py-24 text-peach-100">
      <button
        className={cx(
          'border border-peach-100 font-display rounded-full px-10 py-2 text-current outline-none focus:outline-none',
          {
            'bg-peach-100 text-white': !activeCat,
          }
        )}
        onClick={() => setActiveCat(null)}
      >
        All
      </button>
      {categories.map(({ title, _id }) => (
        <button
          className={cx(
            'border border-peach-100 font-display rounded-full px-10 py-2 text-current outline-none focus:outline-none',
            { 'bg-peach-100 text-white': activeCat === _id }
          )}
          onClick={() => setActiveCat(_id)}
        >
          {title}
        </button>
      ))}
    </div>
  );
};
