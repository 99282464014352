import React from 'react';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import { ImageModule } from '../modules/imageModule';
import { CategoryBadge } from './categoryBadge';

export const BlogCard = ({
  postImage,
  postExcerpt,
  title,
  postDate,
  slug,
  category,
  author,
  _id,
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <Link
      className={cx(
        'flex gap-x-3 group lg:flex-col lg:bg-mint-25 lg:rounded-xl transform transition duration-700',
        {
          'translate-y-20 opacity-0': !inView,
          'translate-y-0 opacity-100': inView,
        }
      )}
      to={`/blog/${slug && slug.current}`}
      ref={ref}
    >
      {/* Image */}
      <div className="w-16 lg:w-full flex-shrink-0 relative bg-mint-100  rounded-xl overflow-hidden">
        <svg className="w-full absolute top-0 left-0 " viewBox="0 0 82.99 70.78">
          <clipPath id={`my-clip-path-${_id}`} clipPathUnits="objectBoundingBox">
            <path d="M1,0.392,0.751,0.003,0.485,0,0.154,0.851 a0.136,0.16,0,0,0,0.005,0.149 l0.308,-0.001 L1,0.658 a0.14,0.164,0,0,0,0.036,-0.266"></path>
          </clipPath>
        </svg>

        <div className="w-full aspect-w-1 aspect-h-1 relative overflow-hidden lg:aspect-w-4 lg:aspect-h-3 transition-all duration-75">
          {postImage && (
            <>
              <ImageModule
                {...postImage}
                className="absolute top-0 left-0 w-full h-full object-cover "
                style={{
                  clipPath: `url(#my-clip-path-${_id})`,
                }}
              />
              <ImageModule
                {...postImage}
                className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 opacity-100 group-hover:opacity-0"
              />
            </>
          )}
        </div>
      </div>
      {/* Details */}
      <div className="lg:px-10 lg:py-10">
        <div className="lg:mb-6">
          <CategoryBadge {...category} />
        </div>
        <h5 className="isH5 my-2 lg:text-3xl">{title}</h5>
        <span className="hidden md:flex">{postDate}</span>
        <p className="hidden lg:block leading-8 text-gray-600 mt-4">{postExcerpt}</p>
        {/* Author */}
        <div className="hidden lg:flex gap-x-3 items-center mt-6">
          {author && (
            <>
              <div>
                <div className="aspect-w-1 aspect-h-1 w-10 relative rounded-full overflow-hidden">
                  {author.profilePicture && (
                    <ImageModule
                      {...author.profilePicture}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  )}
                </div>
              </div>
              <div>
                <h5 className="isH5">By {author.name}</h5>
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
};
