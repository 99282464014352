import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

export const CategoryBadge = ({ title, slug, className }) => {
  return (
    <Link
      to={`/blog/${slug && slug.current}`}
      className={cx(className, {
        'bg-peach-100 text-white text-xs rounded-full font-bold py-1 px-4': true,
      })}
    >
      {title}
    </Link>
  );
};
