import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { useNav } from '../lib/context';

import Layout from '../components/layout';
import { BlogBanner } from '../components/blog/blogBanner';
import { PageMeta } from '../components/pageMeta';
import { BlogCard } from '../components/blog/blogCard';
import { BlogPager } from '../components/blog/pagination';
import { CategoryMenu } from '../components/blog/categoryMenu';

const BlogPortal = ({ data: { page, posts, featuredPost }, pageContext }) => {
  const { title, bannerImage, description, meta } = page || {};
  const [activeCat, setActiveCat] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const breakpoints = useBreakpoint();

  const { setReverseHeader } = useNav();
  useEffect(() => {
    setReverseHeader(true);
  }, []);

  useEffect(() => {
    let results = [...posts.nodes];

    if (activeCat) {
      results = results.filter(({ category }) => activeCat === category._id);
    }

    setFilteredPosts(results);
  }, [activeCat]);

  return (
    <Layout>
      <PageMeta {...meta} />
      <BlogBanner
        image={bannerImage}
        title={title}
        description={description}
        featuredPost={featuredPost.nodes[0]}
      />
      <CategoryMenu pageTitle="All" setActiveCat={setActiveCat} activeCat={activeCat} />
      <div className="grid gap-y-10 px-gutter lg:py-24 lg:grid-cols-2 lg:gap-x-10">
        {breakpoints.md ? (
          <>
            {filteredPosts.map((item, index) => (
              <BlogCard {...item} />
            ))}
          </>
        ) : (
          <>
            {/* Even */}
            <div>
              <div className="grid gap-y-12">
                {filteredPosts.map(
                  (item, index) => index % 2 == 0 && <BlogCard {...item} className="self-start" />
                )}
              </div>
            </div>
            {/* odd */}
            <div className="mt-24">
              <div className="grid gap-y-12">
                {filteredPosts.map(
                  (item, index) => index % 2 != 0 && <BlogCard {...item} className="self-start" />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <BlogPager pageContext={pageContext} />
    </Layout>
  );
};

export default BlogPortal;

export const pageQuery = graphql`
  query blogPortalQuery {
    page: sanityBlogAll {
      title
      description
      bannerImage {
        ...ImageWithPreview
      }
      meta {
        ...MetaCardFields
      }
    }
    featuredPost: allSanityBlogPost(limit: 1, sort: { fields: _createdAt, order: DESC }) {
      nodes {
        ...BlogCard
      }
    }
    posts: allSanityBlogPost(skip: 1, sort: { fields: _createdAt, order: DESC }) {
      nodes {
        ...BlogCard
      }
    }
  }
`;
