import React from 'react';
import { Link } from 'gatsby';
import { ImageModule } from '../modules/imageModule';
import { CategoryBadge } from './categoryBadge';

export const FeaturedBlogCard = ({
  postImage,
  postExcerpt,
  title,
  postDate,
  slug,
  category,
  author,
}) => {
  return (
    <Link
      to={`/blog/${slug && slug.current}`}
      className="bg-mint-25 flex flex-col md:grid md:grid-cols-5 md:bg-transparent rounded-2xl"
    >
      {/* Image */}
      <div className="w-full md:col-start-1 md:col-span-4 md:row-start-1 md:row-end-3 xl:row-end-5">
        <div className="aspect-w-4 aspect-h-3 relative rounded-xl overflow-hidden lg:aspect-w-16 lg:aspect-h-9">
          {postImage && (
            <ImageModule
              {...postImage}
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          )}
        </div>
      </div>
      {/* Details */}
      <div className="px-4 py-10 md:col-start-3 md:col-span-3 md:bg-mint-25 md:row-start-2 md:row-end-4 md:relative md:z-10 md:rounded-2xl md:px-10 md:self-end lg:py-16 xl:col-start-4 xl:col-span-2 xl:row-end-6">
        <div className="lg:mb-6">
          <CategoryBadge {...category} />
        </div>
        <h5 className="isH5 my-2 lg:text-3xl">{title}</h5>
        <span>{postDate}</span>
        <p className="hidden lg:block leading-8 text-gray-600 mt-4">{postExcerpt}</p>
        {/* Author */}
        <div className="flex gap-x-3 items-center mt-6">
          {author && (
            <>
              <div>
                <div className="aspect-w-1 aspect-h-1 w-10 relative rounded-full overflow-hidden">
                  {author.profilePicture && (
                    <ImageModule
                      {...author.profilePicture}
                      className="absolute top-0 left-0 w-full h-full object-cover"
                    />
                  )}
                </div>
              </div>
              <div>
                <h5 className="isH5">By {author.name}</h5>
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
};
