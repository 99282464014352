import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import ChevronL from '../../svg/chevron-l.svg';
import ChevronR from '../../svg/chevron-r.svg';

export const BlogPager = ({ pageContext, pathPrefix = 'blog' }) => {
  const { numberOfPages, pageNumber, previousPagePath, nextPagePath } = pageContext;
  const pageLinks = [];
  for (var i = 0; i < numberOfPages; i++) {
    pageLinks.push({
      label: i + 1,
    });
  }
  return (
    numberOfPages > 1 && (
      <div className="flex gap-x-3 justify-center py-10">
        <Link
          className="w-10 h-10 flex justify-center items-center border border-peach-100 rounded-full"
          to={previousPagePath !== '' && previousPagePath}
        >
          <ChevronL className="w-2 text-peach-100 fill-current" />
        </Link>
        {pageLinks.map(({ label }, index) => (
          <Link
            key={index}
            to={`/${pathPrefix}${index > 0 ? `/${index + 1}` : ''}`}
            className={cx('w-10 h-10 border  flex justify-center items-center rounded-full', {
              'text-peach-100 border-white': index !== pageNumber,
              'text-white bg-peach-100 border-peach-100': index === pageNumber,
            })}
          >
            {label}
          </Link>
        ))}
        <Link
          className="w-10 h-10 flex justify-center items-center border border-peach-100 rounded-full"
          to={nextPagePath !== '' && nextPagePath}
        >
          <ChevronR className="w-2 text-peach-100 fill-current" />
        </Link>
      </div>
    )
  );
};
