import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useBlogCategorys = () => {
  const { allSanityBlogCategory } = useStaticQuery(graphql`
    query {
      allSanityBlogCategory {
        nodes {
          _id
          title
          slug {
            current
          }
        }
      }
    }
  `);
  return allSanityBlogCategory.nodes || [];
};
